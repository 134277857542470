import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateOrderDto } from 'models/OrderModels';
import { CreateOrderItemDto } from 'models/OrderModels';
import { ErrorService } from 'services/error-service';
import { NetOfferService } from 'services/net-offer-service';
import { NetOrderService } from 'services/net-order-service';
import { NetService } from 'services/net-service';
import { ProducerService } from 'services/producer-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("net-offer-create-order");

@autoinject
export class NetOfferCreateOrder {

  private netoffer: Models.NetOffer;
  private createOrderDto: any = null;
  private producers: Array<Models.Producer>;
  private numberOfOrders: number = 0;
  private saving: boolean = false;
  private duplicateArray: Array<any> = [];
  private alreadyExistCriticalArray: Array<any> = [];
  private alreadyExistNotCriticalArray: Array<any> = [];
  private originalObject: any;

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private netOfferService: NetOfferService,
    private netOrderService: NetOrderService,
    private producerService: ProducerService,
    private validationController: ValidationController,
    private validator: Validator,
    private netService: NetService,
    private prompt: Prompt,
    private i18n: I18N,
    private dialogService: DialogService,
    private utility: Utility
  ) { }

  private activate(params) {
    this.getNetOffer(params.Id);
    this.getProducers();
  }

  // Get net and netdimension
  private getNetOffer(id) {
    this.netOfferService
      .get(id + '?$expand=Net')
      .then((res) => {
        this.netoffer = res;

        const items = [];

        for (let i = 0; i < this.netoffer.NumberOfNets; i++) {
          const orderItem = {
            ProducerId: this.netoffer.Net.ProducerId,
            NetIdentifier: null
          };
          items.push(orderItem);
        }
        this.createOrderDto = {
          Items: items
        };
        this.originalObject = {
          Items: items
        };
        
        this.setDefaultProducerPrefixes();

        ValidationRules        
          .ensure('OrderNumber').required().withMessage(this.i18n.tr('general.requiredField'))      
          .on(this.createOrderDto);  
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getProducers() {
    this.producerService
      .getAll('$filter=IsMorenotCompany eq true')
      .then((res) => {
        this.producers = res;

        this.setDefaultProducerPrefixes();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setDefaultProducerPrefixes() {
    if (this.createOrderDto && this.createOrderDto.Items && this.producers) {
      this.createOrderDto.Items.forEach(item => {
        if (item.ProducerId) {
          let producer = this.producers.find(x => x.Id.toString() == item.ProducerId.toString());

          if (producer) {
            item._netIdentifierPrefix = producer.Prefix;
          }
        }
      });
    }
  }

  private getProducerArrayToView() {
    return Promise.resolve(this.producers ? JSON.parse(JSON.stringify(this.producers)) : []);
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.createOrderDto)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.createOrders();
        }
      });
  }

  private createOrders() {
    this.saving = true;
    this.createOrderDto.NetOfferId = this.netoffer.Id;

    if (!this.createOrderDto.OrderNumber || this.createOrderDto.Items.filter((it) => !it.NetIdentifier).length !== 0) {
      this.saving = false;
      this.toastService.showError('general.fillRequiredFields');
      return;
    }

    

    // check for duplicates
    this.duplicateArray = this.createOrderDto.Items.filter((it) => {
      if (!it.NetIdentifier || it.NetIdentifier === '') { return; }
      if (this.createOrderDto.Items.filter((itt) => {
        if (!itt.NetIdentifier || itt.NetIdentifier === '') { return; }
        if (it.NetIdentifier.toLowerCase() === itt.NetIdentifier.toLowerCase()) { return itt; }
      }).length > 1) { return it; }
    });

    if (this.duplicateArray.length !== 0) {
      this.saving = false;
      return this.toastService.showError('order.containsDuplicate');
    }

    this.netService.checkNetIdentifiers(this.createOrderDto.Items.map((it) => it.NetIdentifier))
    .then((duplicates) => {
      this.alreadyExistCriticalArray = duplicates.filter(x => x.IsCritical).map((it) => it.Index);
      this.alreadyExistNotCriticalArray = duplicates.filter(x => !x.IsCritical).map((it) => it.Index);      

      if (this.alreadyExistCriticalArray.length !== 0) {
        // a critical validation error occurred, cannot continue, so show toast/errors
        this.toastService.showError('order.containsAlreadyExistingIdentifiers');
        this.saving = false;
      } else if (this.alreadyExistNotCriticalArray.length !== 0) {
        // a none critical validation error occurred, check if the user wants to continue
        let message =  this.i18n.tr('net.createNetAlreadyExistingIdentifiersNotCritical') + '\n';
        
        duplicates.forEach(d => {
          message = message + '\n' + d.NetIdentifier + ': ' + d.Message;
        });

        this.dialogService.open({
          viewModel: Prompt,
          model: { 
            header: 'net.confirmCreateDuplicateNet', 
            message: message, 
            messagePreparsed: true,
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel'  },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'net.createDuplicateNetAnyway'  }
            } 
          }
        }).whenClosed((response) => {
          if (!response.wasCancelled) {
            const result = response.output;

            logger.debug('result', result);
            if (result === 'continue') {
              this.doCreateOrder();
            } else {
              this.saving = false;
            }
          } else {
            this.saving = false;
          }
        });
      } else {
        this.doCreateOrder();
      }
    })
    .catch((err) => { 
      this.errorService.handleError(err);
      this.saving = false;
    });
  }

  private doCreateOrder() {
    this.netOrderService.createOrder(this.createOrderDto)
        .then((res) => {
          // refresh offer
          this.netOfferService.get(this.netoffer.Id)
            .then((resOffer) => {
              this.netoffer = resOffer;
              this.toastService.showSuccess('order.created_plural');
              this.originalObject = null;
              this.router.navigate('#/offer/' + this.netoffer.Id  + '/net/' + this.netoffer.NetId + '/order');
              this.saving = false;
            })
            .catch((err) => {
              this.saving = false;
              this.errorService.handleError(err);
            });
        })
        .catch((err) => {
          this.errorService.handleError(err);
          this.saving = false;
        });
  }

  private calculateNumberOfOrders() {
    const producers: Array<any> = [];
    this.createOrderDto.Items.forEach((item) => {
        if ( producers.indexOf(item.ProducerId) === -1 && item.ProducerId !== null) {
            producers.push( item.ProducerId) ;
        }
    });

    this.numberOfOrders = producers.length;
  }

  private setProducerId(value, index) {    
    if (!value) {
      return;
    }

    this.clearDuplicateErrors();

    if (this.createOrderDto.Items[index].ProducerId !== value ) {
      this.createOrderDto.Items[index].ProducerId = value;
      this.calculateNumberOfOrders();
    }

    this.setNetIdenfifier(index);
  }

  private netIdentifierChanged(index) {   
    this.clearDuplicateErrors();
    this.setNetIdenfifier(index);
  }

  private setNetIdenfifier(index) {
    this.createOrderDto.Items[index].NetIdentifier = null;

    if (this.createOrderDto.Items[index].ProducerId) {
        let producer = this.producers.find(x => x.Id.toString() == this.createOrderDto.Items[index].ProducerId.toString());

        if (producer) {
          this.createOrderDto.Items[index]._netIdentifierPrefix = producer.Prefix;
        }
    }

    if (this.createOrderDto.Items[index].ProducerId 
      && this.createOrderDto.Items[index].NetIdentifierNumeric 
      && this.createOrderDto.Items[index].NetIdentifierNumeric !== '') {
        if (this.createOrderDto.Items[index]._netIdentifierPrefix) {
          this.createOrderDto.Items[index].NetIdentifier = this.createOrderDto.Items[index]._netIdentifierPrefix + ' - ' + this.createOrderDto.Items[index].NetIdentifierNumeric;
        }        
    }
  }

  private clearDuplicateErrors() {
    this.duplicateArray = [];
    this.alreadyExistCriticalArray = [];
    this.alreadyExistNotCriticalArray = [];
  }

  private canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    if (!this.utility.areEqual(this.createOrderDto, this.originalObject, true)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

}
