import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import {
  NavigationInstruction,
  Next,
  Redirect,
  Router,
  RouterConfiguration,
} from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ImpregnationService } from 'services/impregnation-service';
import { NetOfferService } from 'services/net-offer-service';
import { NetService } from 'services/net-service';
import { OfferStatusService } from 'services/offer-status-service';
import { ReportService } from 'services/report-service';
import { ToastService } from 'services/toast-service';
import { HistoryDialog } from './../history-dialog/history-dialog';
import { AuditLogService } from './../../services/audit-log-service';
import tippy from 'tippy.js';
import { I18N } from 'aurelia-i18n';
import * as moment from 'moment';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('offer-detail-main');

@autoinject
export class NetOfferDetailMain {
  private netoffer: Models.NetOffer;
  private net: Models.Net;
  private statuses: Array<Models.OfferStatus> = [];
  private priceEstimate: any;
  private historicPriceInfoText: string;
  private impregnation: Models.Impregnation;

  private downloadOfferSpecificationProgress: boolean = false;

  private subscription: Subscription;
  showCopyButton: boolean;

  protected is2021Net = false;
  protected netLoaded = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private impregnationService: ImpregnationService,
    private netOfferService: NetOfferService,
    private netService: NetService,
    private offerStatusService: OfferStatusService,
    private reportService: ReportService,
    private router: Router,
    private toastService: ToastService,
    private i18n: I18N,
    private auditLogService: AuditLogService
  ) {}

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'offer-detail-dimension',
        moduleId: PLATFORM.moduleName('./form-dimension'),
        tabindex: 0,
        entityShortName: 'offer',
      },
      {
        route: ['rope'],
        name: 'offer-detail-rope',
        moduleId: PLATFORM.moduleName('./form-rope'),
        tabindex: 1,
        entityShortName: 'offer',
      },
      {
        route: ['netting'],
        name: 'offer-detail-netting',
        moduleId: PLATFORM.moduleName('./form-netting'),
        tabindex: 2,
        entityShortName: 'offer',
      },
      {
        route: ['misc'],
        name: 'offer-detail-misc',
        moduleId: PLATFORM.moduleName('./form-misc'),
        tabindex: 3,
        entityShortName: 'offer',
      },
      {
        route: ['impregnation'],
        name: 'offer-detail-impregnation',
        moduleId: PLATFORM.moduleName('./form-impregnation'),
        tabindex: 4,
        entityShortName: 'offer',
      },
      {
        route: ['preparation'],
        name: 'offer-detail-preparation',
        moduleId: PLATFORM.moduleName('./form-preparation'),
        tabindex: 5,
        entityShortName: 'offer',
      },
      {
        route: ['extra-equipment'],
        name: 'simple-offer-tab-extra-equipment',
        moduleId: PLATFORM.moduleName('../simple-offer/simple-offer-tab-extra-equipment'),
        tabindex: 6,
        entityShortName: 'offer',
      },
      {
        route: ['pricing'],
        name: 'offer-detail-pricing',
        moduleId: PLATFORM.moduleName('./offer-detail-pricing'),
        tabindex: 7,
        entityShortName: 'offer',
      },
      {
        route: ['order'],
        name: 'offer-detail-order',
        moduleId: PLATFORM.moduleName('./offer-detail-order'),
        tabindex: 8,
        entityShortName: 'offer',
      },
    ]);
  }

  private activate(params) {
    this.getNetOffer(params.Id);

    this.getTotalPriceEstimate(params.Id);

    this.offerStatusService
      .getAllCached()
      .then((res) => {
        this.statuses = res
          .sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1))
          .filter((x) => !x.IsDeleted);
      })
      .catch((err) => this.errorService.handleError(err));

    this.subscription = this.eventAggregator.subscribe('offer-refresh', (e) => {
      switch (e) {
        case 'price':
          this.getTotalPriceEstimate(this.netoffer.Id);
          break;

        case 'impregnation':
          this.getImpregnation(this.net.ImpregnationId);
          break;

        case 'offer':
          logger.debug('offer message', this.netoffer.Id);
          this.getNetOffer(this.netoffer.Id);
          break;

        default:
          break;
      }
    });
  }

  private getNetOffer(id) {
    this.netOfferService
      .get(id + '?$expand=Customer,Contact,OfferStatus')
      .then((res) => {
        this.netoffer = res;
        this.getNet(this.netoffer.NetId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private getTotalPriceEstimate(id) {
    this.netOfferService
      .getTotalPriceEstimate(id)
      .then((res) => {
        this.priceEstimate = res;

        if (this.priceEstimate.IsHistoricPrices) {
          let formattedDate = moment
            .utc(this.priceEstimate.PricesCalculated)
            .local()
            .format('DD.MM.YYYY HH:mm:ss');
          this.historicPriceInfoText = this.i18n.tr('offer.historicpriceinfo', {
            formattedDate: formattedDate,
          });
        }
        setTimeout(() => {
          tippy('.priceestimate');
        });
      })
      .catch((err) => {
        // ignore errors on this
      });
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=NetStandard)')
      .then((res) => {
        this.net = res;
        this.is2021Net = res.NetDimension?.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
        this.getImpregnation(this.net.ImpregnationId);
        this.showCopyButton = this.net.NetDimension.NetStandardId != 1;
        this.netLoaded = true;
        logger.debug('NetStandard', this.net.NetDimension.NetStandardId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getImpregnation(id) {
    this.impregnationService
      .get(id + '?$expand=ImpregnationType')
      .then((res) => {
        this.impregnation = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private confirmBeforeDeleteOffer() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.deleteOfferHeading',
          message: 'dialog.deleteOfferAreYouSure',
          actions: {
            delete: { enabled: true, t: 'dialog.deleteOffer' },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'delete') {
            this.deleteOffer();
            return false;
          } else {
            return true;
          }
        }
      });
  }

  private deleteOffer() {
    this.netOfferService
      .delete(this.netoffer.Id)
      .then((res) => {
        this.toastService.showSuccess('offer.deleted');
        this.router.navigateToRoute('offer-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createNetOrder() {
    if (
      this.net.NetDimension.Ns9415Compliant &&
      !this.netoffer.ApprovedQualityCheck
    ) {
      this.toastService.showError(
        'offer.cannotConvertToOrderNotApproved',
        'offer.notQualityCheckedYet'
      );
    } else {
      this.router.navigate(
        '#/offer/' +
          this.netoffer.Id +
          '/net/' +
          this.netoffer.NetId +
          '/convert'
      );
    }
  }

  private downloadOfferSpecification() {
    //offer.notQualityCheckedYet
    //offer.printSpecificationWithoutApprovingNet

    if (this.downloadOfferSpecificationProgress) {
      return;
    }

    if (
      this.net.NetDimension.Ns9415Compliant &&
      !this.netoffer.ApprovedQualityCheck
    ) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'offer.notQualityCheckedYet',
            message: 'offer.printSpecificationWithoutApprovingNet',
            actions: {
              delete: { enabled: false },
              continue: { enabled: true, t: 'dialog.docontinue' },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.doShowSpecification();
              return false;
            } else {
              return true;
            }
          }
        });
    } else {
      this.doShowSpecification();
      return false;
    }
  }

  private doShowSpecification() {
    this.downloadOfferSpecificationProgress = true;
    this.reportService
      .getSpecificationReport(this.netoffer.NetId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.downloadOfferSpecificationProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.downloadOfferSpecificationProgress = false;
      });
  }

  private changeNetOfferStatus(netofferId, statusId) {
    this.netoffer.Contact = null;
    this.netoffer.Customer = null;
    this.netoffer.OfferStatus = null;
    this.netoffer.OfferStatusId = statusId;
    this.netOfferService
      .put(this.netoffer, netofferId)
      .then((result) => {
        this.eventAggregator.publish('dropdownClose', 1);
        this.toastService.showSuccess('offer.updated');

        this.router.navigateToRoute(
          this.router.currentInstruction.config.name,
          this.router.currentInstruction.params,
          { replace: true }
        );
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private showHistory() {
    let viewModel = {
      EntityType: 'Net',
      EntityId: this.netoffer.NetId,
    };

    this.dialogService.open({
      viewModel: HistoryDialog,
      model: viewModel,
      lock: false,
      position: (mc, mo) => {},
    });
  }
}
