import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';

@autoinject
export class LostOfferDialog {
  constructor(private dialogController: DialogController, private i18n: I18N) {}

  reasonCode: string = 'price';
  comments: string;

  save() {
    const offerLostInfo = new Models.NetOfferLostInfo();
    offerLostInfo.ReasonCode = this.reasonCode;
    offerLostInfo.Comments = this.comments;
    this.dialogController.ok(offerLostInfo);
  }

  close() {
    this.dialogController.cancel();
  }

  getReasons() {
    let i = 0;
    return Promise.resolve([
      {
        code: 'price',
        displayName: this.i18n.tr('lost_offer.reason.price'),
        sortOrder: i++,
      },
      {
        code: 'delivery_time',
        displayName: this.i18n.tr('lost_offer.reason.delivery_time'),
        sortOrder: i++,
      },
      {
        code: 'other_supplier',
        displayName: this.i18n.tr('lost_offer.reason.other_supplier'),
        sortOrder: i++,
      },
      {
        code: 'other',
        displayName: this.i18n.tr('lost_offer.reason.other'),
        sortOrder: i++,
      },
    ]);
  }
}
