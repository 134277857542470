import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateOfferDto } from 'models/OfferModels';
import { ErrorService } from 'services/error-service';
import { NetOfferService } from 'services/net-offer-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class OfferNew {
  private netoffer: CreateOfferDto = new CreateOfferDto();
  private today: Date = new Date();
  private originalObject: any = {};
  private saving: boolean = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netOfferService: NetOfferService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private utility: Utility,
    private dialogService: DialogService,
    private prompt: Prompt
  ) {
    ValidationRules
      .ensure('QuoteNumber').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('CustomerId').required()
      .ensure('NumberOfNets').required()
      .on(this.netoffer);
  }

  private activate(params) {
    if (params.CustomerId) {
      this.netoffer.CustomerId = params.CustomerId;
      this.originalObject.CustomerId = params.CustomerId;
    }

    if (params.CopyNetDesignFromExistingNetId) {
      this.netoffer.CopyNetDesignFromExistingNetId = params.CopyNetDesignFromExistingNetId;
    }

    // consider set based on user preferences later
    this.netoffer.CurrencyId = 1; 
  }

  private attached() {

    // Set default ValidTo date to 14 days ahead
    this.netoffer.ValidTo.setDate(this.netoffer.ValidTo.getDate() + 14);
    // Set default PlannedDeliveryDate date to 1 month ahead
    if (this.netoffer.PlannedDeliveryDate) {
      this.netoffer.PlannedDeliveryDate.setMonth(this.netoffer.PlannedDeliveryDate.getMonth() + 1);
    }

    this.originalObject = JSON.parse(JSON.stringify(this.netoffer));
  }

  private createOffer() {

    this.saving = true;

    if (this.netoffer.CopyNetDesignFromExistingNetId == null) {
      this.netOfferService
        .createOffer(this.netoffer)
        .then((res) => {
          this.originalObject = null;
          this.toastService.showSuccess('offer.created');
          this.router.navigateToRoute(res.IsSimpleOffer ? 'simple-offer-detail' : 'offer-detail', { Id: res.Id, NetId: res.NetId });
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          this.errorService.handleError(err);
        });
    } else {
      this.netOfferService
        .createOfferFromNet(this.netoffer)
        .then((res) => {
          this.originalObject = null;
          this.toastService.showSuccess('offer.copied');
          this.router.navigateToRoute(res.IsSimpleOffer ? 'simple-offer-detail' : 'offer-detail', { Id: res.Id, NetId: res.NetId });
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          this.errorService.handleError(err);
        });
    }
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.netoffer)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.createOffer();
        }
      });
  }

  private canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    // fix dates
    this.netoffer = this.utility.areEqualDateFix(this.netoffer, [{ key: 'PlannedDeliveryDate' }, { key: 'ValidTo'}]);
    this.originalObject = this.utility.areEqualDateFix(this.originalObject, [{ key: 'PlannedDeliveryDate' }, { key: 'ValidTo'}]);

    if (!this.utility.areEqual(this.netoffer, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.subFormOpenHeading',
          message: 'dialog.subFormOpenMessage',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel'  },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.continue'  }
          }
        }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  }

}
