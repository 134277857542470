import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CreateOfferDto } from 'models/OfferModels';
import { UserModels } from 'models/UserModels';
import { AddressService } from 'services/address-service';
import { AuthHttpClient } from 'services/auth-http-client';
import { ContactService } from 'services/contact-service';
import { CustomerService } from 'services/customer-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { SiteService } from 'services/site-service';
import { UserService } from 'services/user-service';
import { ParseIntValueConverter } from 'value-converters/parse-int';

@autoinject
export class OfferFormBasic {
  @bindable private netoffer: any;
  @bindable private customers: Array<Models.Customer>;
  @bindable private departments: Array<Models.Department>;
  @bindable private sites: Array<Models.Site>;
  @bindable private contacts: Array<Models.Contact>;
  @bindable private unloadingContacts: Array<Models.Contact>;
  @bindable private addresses: Array<Models.Address>;
  @bindable private context: string;

  
  // BECAUSE OF BUG: If two select2-comp. uses the same source of data,
  // the whole app will just freeze / crash.
  private contacts2: Array<Models.Contact>;

  private httpClient = new HttpClient();
  private userlist: Array<UserModels.User>;

  constructor(
    private addressService: AddressService,
    private contactService: ContactService,
    private customerService: CustomerService,
    private departmentService: DepartmentService,
    private errorService: ErrorService,
    private siteService: SiteService,
    private userService: UserService,
    private parseIntValueConverter: ParseIntValueConverter
  ) { }

  private attached() {
    this.customerService
      .getAll('?$orderby=Name')
      .then((res) => {
        this.customers = res;
        this.getUsers();
      })
      .catch((err) => this.errorService.handleError(err));

    if (this.netoffer.CustomerId && this.netoffer.CustomerId !== '') {
      this.getDepartments(this.netoffer.CustomerId);
      this.getAddresses(this.netoffer.CustomerId);
      this.getContacts(this.netoffer.CustomerId);

      if (this.netoffer.DepartmentId) {
        this.getSites();
      }

      if (this.netoffer.ResponsibleUserId) {
        this.netoffer.ResponsibleUserId = this.netoffer.ResponsibleUserId;
      }

      if (this.netoffer.DepartmentId) {
        const event = {
          detail: {
            value: this.netoffer.DepartmentId
          }
        };
        this.setDepartment(event);
      }

    }
  }

  private getUsers() {
    this.userService
      .getAll()
      .then((res) => {
        this.userlist = res;

        if (this.context === 'new') {

          this.userService.getCurrentUser()
            .then((result) => {
              this.netoffer.ResponsibleUserId = result.Id;
              this.setResponsibleUser(result.Id);
            })
            .catch((err) => this.errorService.handleError(err));
        }

      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getContacts(CustomerId) {
    this.contactService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.contacts = res.filter(x => x.IsResponsibleForOffers || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading));
        this.contacts2 = JSON.parse(JSON.stringify(res.filter(x => x.IsResponsibleForUnloading || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading))));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setQuoteIdCRMAsInteger(event){
    this.netoffer.QuoteIdCRM = event.target.value === "" ? null : this.parseIntValueConverter.fromView(event.target.value);
  } 

  private getUnloadingContacts(CustomerId) {
    this.contactService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.unloadingContacts = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getDepartments(CustomerId) {
    this.departmentService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.departments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAddresses(CustomerId) {
    this.addressService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {        
        this.addresses = this.addressService.addFullAddressProperty(res);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites() {
    let dep: string = '';

    if (this.netoffer.DepartmentId) {
      dep = ' and DepartmentId eq ' + this.netoffer.DepartmentId;
    }
    this.siteService.getAll('?$filter=CustomerId eq ' + this.netoffer.CustomerId + dep)
      .then((res) => {
        this.sites = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setCustomer(evt) {
    if (evt.detail.value !== this.netoffer.CustomerId) {
      this.contacts = [];
      this.unloadingContacts = [];
      this.sites = [];
      this.departments = [];
      this.netoffer.CustomerId = evt.detail.value;
      this.getDepartments(this.netoffer.CustomerId);
      this.getAddresses(this.netoffer.CustomerId);
      this.getContacts(this.netoffer.CustomerId);
      this.getUnloadingContacts(this.netoffer.CustomerId);
    }
  }

  private setDepartment(evt) {

    if (evt.detail.value !== this.netoffer.DepartmentId) {      
      this.netoffer.DepartmentId = evt.detail.value;
      this.sites = [];
      this.getSites();
    }
  }

  private setUnloadingContact(value) {
    if (!value || value.toString() === this.netoffer.UnloadingContactId.toString() ) {
      return;
    }
    this.netoffer.UnloadingContactId = value.toString();
  }

  private setResponsibleUser(value) {
    if (!value || value.toString() === this.netoffer.ResponsibleUserId.toString() ) {
      return;
    }
    this.netoffer.ResponsibleUserId = value.toString();
  }

}
