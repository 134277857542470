import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CreateOfferDto } from 'models/OfferModels';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetOfferService } from 'services/net-offer-service';
import { NetOrderService } from 'services/net-order-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class OfferEdit {
  private netoffer: Models.NetOffer = new Models.NetOffer();
  private originalObject;

  constructor(
    private dialogService: DialogService,
    private netOfferService: NetOfferService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private validator: Validator,
    private validationController: ValidationController,
    private i18n: I18N,
    private deleteDialogService: DeleteDialogService,
    private utility: Utility,
    private netOrderService: NetOrderService
  ) {
    ValidationRules
      .ensure('QuoteNumber').required().withMessage(i18n.tr('general.requiredField'))
      .ensure('CustomerId').required()
      .ensure('CustomerId').minLength(1)
      .ensure('NumberOfNets').required()
      .on(this.netoffer);
  }

  private activate(params) {
    this.netOfferService
      .get(params.Id)
      .then((netOffer) => {
        this.netoffer = netOffer;
        this.originalObject = JSON.parse(JSON.stringify(netOffer));

        ValidationRules
        .ensure('QuoteNumber').required().withMessage(this.i18n.tr('general.requiredField'))
        .ensure('CustomerId').required()
        .ensure('NumberOfNets').required()
        .on(this.netoffer);

      })
      .catch((err) => this.errorService.handleError(err));

  }

  private updateNetOffer() {
    this.netOfferService
      .put(this.netoffer, this.netoffer.Id)
      .then((res) => {
        this.originalObject = null;
        this.eventAggregator.publish('netOfferListReset', 1);
        this.toastService.showSuccess('netOffer.updated');
        const targetRoute = this.netoffer.IsSimpleOffer ? 'simple-offer-detail' : 'offer-detail';
        this.router.navigateToRoute(targetRoute, { Id: this.netoffer.Id, NetId: this.netoffer.NetId });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private confirmBeforeDeleteOffer() {
    this.deleteDialogService.confirmBeforeDelete(
        () => {
          this.deleteOffer();
        },
        'dialog.deleteOfferHeading',
        'dialog.deleteOfferAreYouSure',
        'dialog.deleteOffer'
      );
  }

  private deleteOffer() {

    this.netOrderService
    .getAll('?$filter=NetOfferId eq ' + this.netoffer.Id)
    .then((res) => {

      if (res && res.length > 0) {
        return this.toastService.showError('offer.deleteDeclined');
      }

      this.netOfferService
      .deleteOffer(this.netoffer.Id)
      .then((ress) => {
        this.toastService.showSuccess('netOffer.deleted');
        this.router.navigateToRoute('offer-list');
      })
      .catch((err) => this.errorService.handleError(err));

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private gotoParentView() {
    this.eventAggregator.publish('netOfferListReset', 1);
    this.router.navigateToRoute('netOffer-list');
  }

  private validateOffer() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.netoffer)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateNetOffer();
        }
      });
  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.netoffer, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            // Save the service and let that function handle the rest of the logic
            this.updateNetOffer();
            return false;
          } else {
            return true;
          }
        }
      });
    } else {
      return true;
    }
  }

}
