import { NetOfferService } from 'services/net-offer-service';
import { bindable, autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class OfferList {
  @bindable private customerid: number;

  private exportDetailedReportProgress: boolean = false;
  private exportProgress: boolean = false;

  private OFFER_FILTERS_KEY = 'OFFER_FILTERS_KEY_full-view';

  constructor(private netOfferService: NetOfferService, private eventAggregator: EventAggregator,) {
 
  }


  private params: any;
  private pageSize: number = 10;

  private activate(params) {
    this.params = params;
  }

  exportDetailedReport() {
    let filters = JSON.parse(localStorage.getItem(this.OFFER_FILTERS_KEY));    
    this.netOfferService.exportDetailedReport(filters);
  }

  export() {
    let filters = JSON.parse(localStorage.getItem(this.OFFER_FILTERS_KEY));    
    this.netOfferService.exportList(filters);
  }

  newOffer() {
    this.eventAggregator.publish('CreateOfferDialogOpen', true);
  }

}
