import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Models } from 'models/core';
import { CreateOrderDto } from 'models/OrderModels';
import { CreateOrderItemDto } from 'models/OrderModels';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetOfferService } from 'services/net-offer-service';
import { NetOrderService } from 'services/net-order-service';
import { ProducerService } from 'services/producer-service';
import { RopeService } from 'services/rope-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class NetOfferDetailOrder {
  private netoffer: Models.NetOffer;
  private createOrderDto: CreateOrderDto = new CreateOrderDto();
  private ropes: Array<Models.Rope>;
  private loops: Array<Models.Loop>;
  private ropeNewFormVisible: boolean = false;
  private ropeEditFormVisible: number = null;
  private loopNewFormVisible: boolean = false;
  private loopEditFormVisible: number = null;
  private producers: Array<Models.Producer>;
  private orders: Array<Models.NetOrder>;
  private numberOfOrders: number = null;

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private netOfferService: NetOfferService,
    private netOrderService: NetOrderService,
    private producerService: ProducerService
  ) { }

  private activate(params) {
    this.getNetOffer(params.Id);
  }

  private attached() {
    this.getProducers();
  }

  // Get net and netdimension
  private getNetOffer(id) {
    this.netOfferService
      .get(id)
      .then((res) => {
        this.netoffer = res;
        this.getOrders();
        const items = [];
        for (let i = 0; i < this.netoffer.NumberOfNets; i++) {
          const orderItem: CreateOrderItemDto = {
            ProducerId: null,
            NetIdentifier: null
          };
          items.push(orderItem);
        }
        this.createOrderDto.Items = items;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getOrders() {
    const filters = {
      NetOfferId: this.netoffer.Id
    };
    this.netOrderService
      .getList(filters)
      .then((res) => {
        if (res.headers.get('x-total-count') > 0) {
          this.numberOfOrders = res.headers.get('x-total-count');
          return res.text().then((responseText) => {
            this.orders = JSON.parse(responseText);
          });
        } else {
          this.numberOfOrders = 0;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getProducers() {
    this.producerService
      .getAll()
      .then((res) => {
        this.producers = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createOrders() {
    this.createOrderDto.NetOfferId = this.netoffer.Id;
    this.netOrderService
      .createOrder(this.createOrderDto)
      .then((res) => {
        this.numberOfOrders = res.length;
        this.getOrders();
        this.toastService.showSuccess('order.created_plural');
        this.router.navigateToRoute('offer-detail-order', {}, { replace: true, trigger: true });
      })
      .catch((err) => this.errorService.handleError(err));
  }

}
